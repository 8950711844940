import $ from 'jquery';
import 'what-input';
import Swiper, {FreeMode, Scrollbar, Mousewheel} from 'swiper';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
$(document).foundation();

// swiper instance for tabs
if($('.tabsSwiper').length) {
    var tabsSwiper = new Swiper(".tabsSwiper", {
        modules: [FreeMode],
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: true,
        enabled: true,
        breakpoints: {
            1200: {
                slidesPerView: 'auto',
                enabled: false,
            }
        }
    });
}

if($('.valuesSwiper').length) {
    var valuesSwiper = new Swiper(".valuesSwiper", {
        modules: [FreeMode, Scrollbar, Mousewheel],
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: true,
        direction: "horizontal",
        scrollbar: {
            el: ".swiper-scrollbar",
          },
        mousewheel: true,
        enabled: true,
        breakpoints: {
            1200: {
                slidesPerView: 'auto',
                enabled: true,
            }
        }
    });

    valuesSwiper.on('touchStart', (swiper, event) => {swiper.el.style.cursor = 'grabbing'});
    valuesSwiper.on('touchEnd', (swiper, event) => {swiper.el.style.cursor = 'grab'});
}

if($('header.main-header').hasClass("dynamic")){
    $('.main-navigation').on('show.zf.dropdownMenu', function(){
        $('header.main-header').removeClass("is-light");
    });
    $('.main-navigation').on('hide.zf.dropdownMenu', function(){
        if($('.is-anchored').length){
            $('header.main-header').addClass("is-light");
        };
    });
}

$('.header-container').on('sticky.zf.stuckto:top', function(){
    if($('header.main-header').hasClass("dynamic")){
        $('header.main-header').removeClass("is-light");
    }
    if($('.js-offcanvasTrigger').hasClass("dynamic")){
        $('.js-offcanvasTrigger').removeClass("is-light");
        $('.js-offcanvasTrigger').addClass("stuckTrigger");
    } else {
        $('.js-offcanvasTrigger').addClass("stuckTrigger");
    }
});
$('.header-container').on('sticky.zf.unstuckfrom:top', function(){
    if($('header.main-header').hasClass("dynamic")){
        $('header.main-header').addClass("is-light");
    }
    if($('.js-offcanvasTrigger').hasClass("dynamic")){
        $('.js-offcanvasTrigger').addClass("is-light");
        $('.js-offcanvasTrigger').removeClass("stuckTrigger");
    } else {
        $('.js-offcanvasTrigger').addClass("stuckTrigger");
    }
});


const mainNavSubItems = document.querySelectorAll('ul.main-navigation .is-dropdown-submenu');

function adjustSubmenu() {
    const mainNav = document.querySelector('ul.main-navigation');
    const mainNavDistRight = mainNav.getBoundingClientRect().right;
    const mainNavDistLeft = mainNav.getBoundingClientRect().left;
    mainNavSubItems.forEach(item => {
        item.style.paddingRight = window.innerWidth - mainNavDistRight + "px";
        item.style.paddingLeft = mainNavDistLeft - 5 + "px";
    });
}
adjustSubmenu();
window.onresize = adjustSubmenu;
window.onscroll = adjustSubmenu;

if ($('#dna-tabs').length) {
    const dnaTabElement = $('#dna-tabs');
    var dnaTabs = new Foundation.Tabs(dnaTabElement);
}

// offcanvas
if($('.js-offcanvasTrigger').length) {
    const offcanvasTrigger = document.querySelector('.js-offcanvasTrigger');
    const htmlElement = document.querySelector('html');
    offcanvasTrigger.addEventListener('click', () => {
        htmlElement.classList.toggle('openedOffcanvas');
        offcanvasTrigger.classList.toggle('active');
    });
}

// video
if($('.js-bgVideo').length) {
    var videoSource = document.querySelector('.js-bgVideo').dataset.videoSource;
    var videoImage = document.querySelector('.js-bgVideo').dataset.videoImage;

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        checkViewportVideo();
    });

    function checkViewportVideo(){
        if(Foundation.MediaQuery.atLeast('medium')) {
            console.log('large video');
            var videoTag = `
            <video autoplay loop muted
                poster="${videoImage}"
                class="of-cover" >
                <source
                src="${videoSource}"
                type="video/mp4">
            </video>`;
        document.querySelector('.js-bgVideo').innerHTML = videoTag;
        } else if (Foundation.MediaQuery.atLeast('small')) {
            console.log('no video');
            var videoTag = `
            <video autoplay loop muted
                poster="${videoImage}"
                class="of-cover" >
            </video>`;
            document.querySelector('.js-bgVideo').innerHTML = videoTag;
        }
    }

    $( function() {
        checkViewportVideo();
    });
}

// anchors
const scrollOpts = {
    offset: 140
}

if ( $( ".anchorLink" ).length ) {
    var anchorItem = $('.anchorLink');
    var anchorItem_ss = new Foundation.SmoothScroll(anchorItem, scrollOpts);
}

if ( $('.js-infoIcon').length) {
    const infoIcons = document.querySelectorAll('.js-infoIcon');
    // console.log(infoIcons);
    infoIcons.forEach((infoIcon) => {
        infoIcon.addEventListener('mouseover', () => {
            document.getElementById(infoIcon.dataset.infoContent).parentElement.classList.add('show-desc')
        })
    });
    infoIcons.forEach((infoIcon) => {
        infoIcon.addEventListener('mouseout', () => {document.getElementById(infoIcon.dataset.infoContent).parentElement.classList.remove('show-desc')})
    });
}


const companyLinks = document.querySelectorAll('.js-companyList a');
companyLinks.forEach(function(link) {
    link.addEventListener('mouseover', function(){
        const companyId = this.dataset.company;
        hideCompaniesTexts();
        deactivateCompaniesLinks();
        this.classList.add('active');
        document.querySelector(`.js-companyText hgroup[data-company-id="${companyId}"]`).classList.remove('hide');
    })
});

function deactivateCompaniesLinks() {
    document.querySelectorAll('.js-companyList a').forEach(textLink => { textLink.classList.remove('active')});
}

function hideCompaniesTexts() {
    document.querySelectorAll('.js-companyText hgroup').forEach(textGroup => { textGroup.classList.add('hide')});
}

$("#partnersAccordion").on("up.zf.accordion", function(event) {
    setTimeout(function(){
        $('html,body').animate({scrollTop: $('.is-active').offset().top - 120}, 'slow');
    }, 250); //Adjust to match slideSpeed
}); 